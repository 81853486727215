<template>
  <v-card>
    <v-card-title class="mb-5">
      <span class="text-h6">{{ $t("labels.add_employee_process") }}</span>
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        :error="validates.includes('id_employee')"
        v-model="id_employee"
        :items="employees"
        :label="$t('labels.select_employee_1')"
        dense
        outlined
        hide-details
      ></v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
      <v-btn color="primary" @click="addEmployee">
        {{ $t("labels.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "AddEmployee",
  props: {
    idFindGoods: {
      type: Number,
      default: () => 0,
    },
    idWarehouse: {
      type: Number,
      default: () => 0,
    },
    processingEmployees: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    id_employee: null,
    validates: [],
    employees: [],
    isLoading: false,
  }),
  methods: {
    async addEmployee() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/find-goods/v1/manager-add-employee", {
          id_employee: this.id_employee,
          id_find_goods: this.idFindGoods,
        });
        this.id_employee = null;
        this.refreshData();
        this.isLoading = false;
        this.closeDialog();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getEmployees() {
      const { data } = await httpClient.post(
        "/employee/v1/get-by-my-warehouse",
        {
          id_warehouse: this.idWarehouse,
          status: 10,
        }
      );
      const employees = [];
      const processingEmployeeIds = [...this.processingEmployees].map(
        (pe) => pe.id_employee
      );
      data.forEach((emp) => {
        if (!processingEmployeeIds.includes(emp.id)) {
          employees.push({
            value: emp.id,
            text: emp.full_name || "",
          });
        }
      });
      this.employees = [...employees];
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    removeValidate(name) {
      this.validates = this.validates.filter((v) => v !== name);
    },
  },
  mounted() {
    this.getEmployees();
  },
};
</script>

<style scoped></style>
